<template>
    <div class="container">
      <a-collapse v-model:activeKey="activeKey" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">
        <a-collapse-panel key="1" >
          <template #header>
            <h1>EMPLOYEE</h1>
          </template>
          <div class="controller">
            <div class="item">
              <span>ตัวกรองบริษัท: </span>
              <a-select v-model:value="selectedCompanyID" @change="get_users" style="width: 300px">
                <a-select-option :value="null">ทั้งหมด</a-select-option>
                <a-select-option v-for="company in company_list" :key="company.id" :value="company.id" >{{ company.name }}</a-select-option>
              </a-select>
            </div>

            <div class="group-item">
              <a-button class="item" type="primary" :disabled="!selectedRowKeys.length" @click="bulk_export" style="display: flex;align-items: center;width: fit-content;align-self: flex-end;width: 184.61px">
                <span class="material-symbols-outlined" style="font-size: 18px">heap_snapshot_large</span>
                <span>ส่งออกสัญญา ({{ selectedRowKeys.length }})</span>
              </a-button>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-divider />
      <div class="wrapper" style="height: 80%">
        <a-table style="width: 100%" size="small"
          :columns="[
            { title: 'อีเมลล์', dataIndex: 'email', width: 250 },
            { title: 'ชื่อ-สกุล', dataIndex: 'fullname', width: 200  },
            { title: 'เบอร์โทรศัพท์', dataIndex: 'phone', width: 150  },
            { title: 'บริษัท', dataIndex: 'company_id', ellipsis: true, width: 150,  sorter: (a, b) => a.company_id - b.company_id },
            { title: 'บัญชีธนาคาร', dataIndex: 'bank_name', ellipsis: true, width: 150 , sorter: (a, b) => `${a.bank_name}${a.bank_no}`.localeCompare(`${b.bank_name}${b.bank_no}`) },
            { title: 'สถานะ', dataIndex: 'is_active', width: 125, align: 'center' },
            { title: 'ข้อมูลจากบริษัท', dataIndex: 'is_imported', width: 125, align: 'center'  },
            { title: 'ประวัติ', dataIndex: 'order', width: 150 },
            { title: 'ตัวเลือก', dataIndex: 'id', width: 200, align: 'start', fixed: 'right' }
          ]"
          :scroll="{ x: 100, y: 500 }"
          :data-source="filtered_list(user_list)"
          :pagination="false"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          rowKey="id"
        >
          <template #bodyCell="{ column: { dataIndex }, record: { id, partner_id, is_active, is_imported, bank_name, company, bank_no, orders }, index}">
            <template v-if="dataIndex === 'bank_name'">
              <a-tooltip>
                <template #title>{{ bank_name }} - {{ bank_no }}</template>
                {{ bank_name }} - {{ bank_no }}
              </a-tooltip>
            </template>
            <template v-if="dataIndex === 'company_id'" >
              {{ company?.name }}
            </template>
            <template v-if="dataIndex === 'id'" >
              <a-tooltip v-if="partner_id == 6" placement="topLeft" title="ยืนยันผู้ใช้">
                <a @click="humansoft_approved(id)"><span class="material-symbols-outlined">verified</span></a>
              </a-tooltip>
              <a-tooltip v-else placement="topLeft" title="ยืนยันแล้ว" disabled>
                <a disabled><span class="material-symbols-outlined">verified</span></a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip placement="topLeft" title="ส่งออกสัญญา">
                <a @click="export_pdpa(filtered_list(user_list)[index].id)"><span style="font-size: 16px" class="material-symbols-outlined">share</span></a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip placement="topLeft" title="แก้ไข">
                <a @click="show_create_user(filtered_list(user_list)[index])"><span style="font-size: 16px" class="material-symbols-outlined">edit</span></a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip placement="topLeft" title="ลบ">
                <a @click="delete_user(id)"><span style="font-size: 16px" class="material-symbols-outlined">delete</span></a>
              </a-tooltip>
            </template>
            <template v-if="dataIndex === 'order'" >
              <a-popover title="ประวัติการขอเบิก">
                <template #content>
                  <a-statistic v-for="order, i in orders" :key="i" :title="`#${i+1} - ${order.created_at}`" :value="order.amount.toLocaleString()" />
                </template>
                <a> ประวัติการขอเบิก ({{ orders.length }})</a>
              </a-popover>
            </template>
            <template v-if="dataIndex === 'is_active'">
              <a-tag v-if="is_active == 1" :color="'success'">กำลังใช้งาน</a-tag>
              <a-tag v-else :color="'warning'">ไม่ได้ใช้งาน</a-tag>
            </template>
            <template v-if="dataIndex === 'is_imported'">
              <a-tag v-if="is_imported == 1" :color="'success'">นำเข้าแล้ว</a-tag>
              <a-tag v-else-if="partner_id == 6" :color="'error'">HUMANSOFT</a-tag>
              <a-tag v-else :color="'warning'">ยังไม่ได้นำเข้า</a-tag>
            </template>
          </template>

        </a-table>
        <a-pagination
          style="margin-top: 1rem;"
          v-model:current="page"
          v-model:pageSize="perpage"
          show-size-changer
          :total="user_total"
          @change="get_users()"
        />
      </div>

      <!-- Create Company Drawer -->
      <a-drawer
        :closable="true"
        :visible="create_user_modal"
        :destroyOnClose="true"
        :maskClosable="true"
        :width="600"
        title="ข้อมูลพนักงาน"
        @close="create_user_modal = false"
      >
        <a-form
          :model="create_user_form"
          layout="vertical"
          @finish="submit_create_user"
        >
          <a-divider orientation="left" plain>
            <h3># ข้อมูลทั่วไป</h3>
          </a-divider>

          <a-form-item name="is_active" label="กำลังใช้งาน" required>
            <a-switch v-model:checked="create_user_form.is_active" />
          </a-form-item>

          <a-form-item name="fullname" label="บริษัท" required>
            <a-select v-model:value="create_user_form.company_id" >
              <a-select-option v-for="{ id, name } in company_list" :key="id" :value="id">{{ name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item name="fullname" label="ชื่อพนักงาน" required>
            <a-input v-model:value="create_user_form.fullname" />
          </a-form-item>

          <a-row justify="space-between">
            <a-col :span="11">
              <a-form-item name="phone" label="เบอร์โทรศัพท์" required>
                <a-input v-model:value="create_user_form.phone" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item name="email" label="อีเมล" required>
                <a-input v-model:value="create_user_form.email" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-divider orientation="left" plain>
            <h3># การตั้งค่าวงเงิน</h3>
          </a-divider>

          <a-row justify="space-between">
            <a-col :span="24">
              <a-form-item name="salary" label="เงินเดือน" required>
                <a-input v-model:value="create_user_form.salary" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row justify="space-between">
            <a-col :span="11">
              <a-form-item name="bank_name" label="ธนาคาร">
                <a-input v-model:value="create_user_form.bank_name" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item name="bank_no" label="เลขบัญชี">
                <a-input v-model:value="create_user_form.bank_no" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item>
            <a-button type="primary" html-type="submit">บันทึก</a-button>
          </a-form-item>
        </a-form>
      </a-drawer>

    </div>
  </template>
  
  <script>
  import AdminService from '../api/AdminService';
  import Swal from 'sweetalert2';
  import saveAs from 'file-saver';
  
  export default {
    name: 'User',
    data () {
      return {
        user_list: [],
        company_list: [],
        activeKey: ['1'],
        selectedRowKeys: [],
        selectedCompanyID: null,
        page: 1,
        perpage: 20,
        user_total: 0,
        create_user_modal: false,
        create_user_form: {
          id: null,
          is_active: false,
          company_id: null,
          username: null,
          password: null,
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          role: null,
          status: null,
          remark: null,
        },
      }
    },
    methods: {
      humansoft_approved(id) {
        Swal.fire({
          title: 'ยืนยันการอนุมัติ',
          text: "คุณต้องการยืนยันการอนุมัติใช่หรือไม่?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1890ff',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ใช่, ยืนยัน!',
          showDenyButton: true,
          denyButtonColor: '#d33',
          denyButtonText: 'ไม่, ยกเลิก!',
          cancelButtonText: 'ยกเลิก'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$message.success({ content: 'ยืนยันการอนุมัติสำเร็จ!', key: 'humansoft_approved', duration: 1.5 });
            //TODO Internal Approve user API
            console.log(id);
          }
        })
      },
      bulk_export() {
        for (var i = 0;i< this.selectedRowKeys.length; i++) {
          this.export_pdpa(this.selectedRowKeys[i])
        }
      },
      onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
      },
      async export_pdpa(id) {
        this.$message.loading({ content: 'กำลังสร้างไฟล์...', key: 'export' });
        const { base64 } = await AdminService.export_pdpa(id)
        const buffer = Buffer.from(base64, 'base64')
        saveAs(new Blob([buffer]), `PDPA-${id.toString().padStart(6,'0')}.docx`)
        this.$message.success({ content: 'สร้างไฟล์สำเร็จ', key: 'export' });
      },
      show_create_user(selected) {
        if (selected) {
          this.create_user_form = selected
          this.create_user_form.is_active = selected.is_active == 1? true:false
          this.create_user_form.company_id = selected.company_id
        }
        setTimeout(() => {
          this.create_user_modal = true
        }, 100);
      },
      submit_create_user() {
        const { id } = this.create_user_form
        this.$message.loading({ content: 'Loading...', key: 'create_user' });
        if (id) {
          AdminService.update_model('User', id, { data: this.create_user_form })
            .then(() => {
              this.$message.success({ content: 'Update Success!', key: 'create_user', duration: 1.5 });
              this.create_user_modal = false
              this.clear_form()
              this.get_users()
            })
            .catch((err) => {
              console.log(err);
              this.$message.error({ content: 'Update Failed!', key: 'create_user', duration: 1.5 });
            })
        }
      },
      clear_form() {
        this.create_user_form = {
          id: null,
          is_active: false,
          company_id: null,
          username: null,
          password: null,
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          role: null,
          status: null,
          remark: null,
        }
      },
      delete_user(id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1890ff',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$message.loading({ content: 'Loading...', key: 'delete_user' });
            AdminService.delete_model('User', id)
              .then(() => {
                this.$message.success({ content: 'Delete Success!', key: 'delete_user', duration: 1.5 });
                this.get_users()
              })
              .catch(() => {
                this.$message.error({ content: 'Delete Failed!', key: 'delete_user', duration: 1.5 });
              })
          }
        })
      },
      filtered_list(list) {
        return list
          .filter(item => {
            // if (this.selectedCompanyID) {
            //   return item.company_id === this.selectedCompanyID
            // }
            return true
          })
      },
      async get_users() {
        const res = await AdminService.get_model('User', { 
          id: 'all',
          load: ['company','orders'], 
          page: this.page,
          perpage: this.perpage,
          where: this.selectedCompanyID? [`company_id:${this.selectedCompanyID}`]:[]
        });

        this.user_total = res.total
        this.user_list = res.data.filter(item => item.fullname != null)
      },
      async get_company() {
        const res = await AdminService.get_model('Company', { id: 'all', page: 1, perpage: 500  });
        this.company_list = res.data
      },
    },
    mounted() {
      this.get_users()
      this.get_company()
    }
  }
  </script>
  